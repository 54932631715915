.wow-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 1;
  background: linear-gradient(to top, rgb(255, 255, 255), rgba(74, 186, 241, 0.1));
}

.wow-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  text-align: center;
  color: #0F3A57;
}

.wow-main .swiper {
  width: 100%;
  height: 100%;
  padding-bottom: 56px !important;
}

.wow-main .swiper-slide {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.2;
  transition: .6s ease all;
  cursor: pointer;
}

.wow-main .swiper-slide img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -50%;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  height: 618px;
  max-width: unset !important;
}

.wow-main .swiper-slide-active,
.wow-main .swiper-slide-duplicate-active {
  opacity: 1;
  scale: 1.1;
}

.wow-main .swiper-slide-next,
.wow-main .swiper-slide-duplicate-next {
  opacity: 1;
}

.wow-main .swiper-slide-prev,
.wow-main .swiper-slide-duplicate-prev {
  opacity: 1;
}

.wow-main .swiper-scrollbar.swiper-scrollbar-horizontal {
  border-radius: 20px !important;
  height: 2px !important;
  width: 896px !important;
  bottom: 0 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background: #0000001A !important;
}

.wow-main .swiper-scrollbar-drag {
  background: #49BAF1 !important;

}

.wow-main .card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
}

.wow-main .image-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 432px;
  width: 100%;
  overflow: hidden;
}

.wow-main .name {
  text-align: left;
  font-size: 48px;
  font-family: 'Roboto Flex';
  font-weight: 500;
  color: #0F3A57;
  letter-spacing: -0.06em;
}

.wow-main .description {
  font-size: 20px;
  font-family: 'Roboto Flex';
  font-weight: 400;
  color: #0F3A5766;
  margin-bottom: 1.5rem;
}


.wow-main .number {
  display: flex;
  align-items: center;
  gap: 16px;
}

.wow-main .number h5 {
  font-size: 24px;
  font-family: 'Roboto Flex';
  font-weight: 200;
  letter-spacing: -0.06em;
  color: #0F3A5733;
  margin: 0;
}


.wow-main .swiper-navBtn {
  color: #6e93f7;
  transition: all 0.3s ease;
}

.wow-main .swiper-navBtn:hover {
  color: #4070f4;
}

.wow-main .swiper-navBtn::before,
.wow-main .swiper-navBtn::after {
  font-size: 35px;
}

.wow-main .swiper-button-next {
  right: 0;
}

.wow-main .swiper-button-prev {
  left: 0;
}

.wow-main .swiper-pagination-bullet {
  background-color: #6e93f7;
  opacity: 1;
}

.wow-main .swiper-pagination-bullet-active {
  background-color: #4070f4;
}

.wow-main .swiper .line {
  background: #0F3A5733;
  width: 95px;
  height: 1px;
}

@media (max-width: 768px) {
  .wow-main .swiper-navBtn {
    display: none;
  }
}


/* Mobile */
@media only screen and (max-width: 480px) {
  .wow-main .swiper-slide img {
    position: unset !important;
  }

  .wow-main .card-content {
    align-items: center;
  }

  .wow-main .image-content {
    height: 300px;
    width: 90vw;
    margin:auto;
  }

  .wow-main .swiper-slide img {
    height: 300px;
  }
  .wow-main .wow-content p {
    max-width: 400px;
  }
  .wow-main .swiper-scrollbar.swiper-scrollbar-horizontal {
    width: 90vw !important;
  }
}

/* Tablets */
@media only screen and (min-width: 481px) and (max-width:768px) {
  .wow-main .swiper-slide img {
    position: unset !important;
  }

  .wow-main .card-content {
    align-items: center;
  }

  .wow-main .image-content {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:auto;
  }

  .wow-main .swiper-slide img {
    height: 300px;
  }

  .wow-main .wow-content p {
    width: 450px;
  }
  .wow-main .swiper {
    height: 540px;
  }
  .wow-main .swiper-scrollbar.swiper-scrollbar-horizontal {
    width: 400px !important;
  }
}

/* Small screens */
@media only screen and (min-width: 769px) and (max-width:1024px) {
  .wow-main .image-content {
    height: 400px;
  }

  .wow-main .swiper-slide img {
    height: 400px;
  }

  .wow-main .wow-content p {
    width: 630px;
  }
  .wow-main .swiper {
    height: 640px;
  }
  .wow-main .swiper-scrollbar.swiper-scrollbar-horizontal {
    width: 540px !important;
  }
}

/* Large screens */
@media only screen and (min-width: 1025px) and (max-width:1200px) {
  .wow-main .image-content {
    height: 500px;
  }

  .wow-main .swiper-slide img {
    height: 500px;
  }

  .wow-main .wow-content p {
    width: 770px;
  }

  .wow-main .swiper {
    height: 740px;
  }

  .wow-main .swiper-scrollbar.swiper-scrollbar-horizontal {
    width: 740px !important;
  }
}

/* Extra large screens */
@media only screen and (min-width: 1201px) {
  .wow-main .image-content {
    height: 618px;
  }

  .wow-main .swiper-slide img {
    height: 618px;
  }


  .wow-main .wow-content p {
    width: 1020px;
  }

  .wow-main .swiper {
    height: 940px;
  }
}