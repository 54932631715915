.wow-main {
  z-index: 1;
  background: linear-gradient(to top, #fff, #4abaf11a);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
}

.wow-content {
  z-index: 1;
  text-align: center;
  color: #0f3a57;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.wow-main .swiper {
  width: 100%;
  height: 100%;
  padding-bottom: 56px !important;
}

.wow-main .swiper-slide {
  text-align: center;
  opacity: .2;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all .6s;
  display: flex;
}

.wow-main .swiper-slide img {
  height: 618px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  inset: 0 0 0 -50%;
  overflow: hidden;
  max-width: unset !important;
}

.wow-main .swiper-slide-active, .wow-main .swiper-slide-duplicate-active {
  opacity: 1;
  scale: 1.1;
}

.wow-main .swiper-slide-next, .wow-main .swiper-slide-duplicate-next, .wow-main .swiper-slide-prev, .wow-main .swiper-slide-duplicate-prev {
  opacity: 1;
}

.wow-main .swiper-scrollbar.swiper-scrollbar-horizontal {
  height: 2px !important;
  width: 896px !important;
  background: #0000001a !important;
  border-radius: 20px !important;
  bottom: 0 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.wow-main .swiper-scrollbar-drag {
  background: #49baf1 !important;
}

.wow-main .card-content {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  display: flex;
}

.wow-main .image-content {
  max-width: 432px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.wow-main .name {
  text-align: left;
  color: #0f3a57;
  letter-spacing: -.06em;
  font-family: Roboto Flex;
  font-size: 48px;
  font-weight: 500;
}

.wow-main .description {
  color: #0f3a5766;
  margin-bottom: 1.5rem;
  font-family: Roboto Flex;
  font-size: 20px;
  font-weight: 400;
}

.wow-main .number {
  align-items: center;
  gap: 16px;
  display: flex;
}

.wow-main .number h5 {
  letter-spacing: -.06em;
  color: #0f3a5733;
  margin: 0;
  font-family: Roboto Flex;
  font-size: 24px;
  font-weight: 200;
}

.wow-main .swiper-navBtn {
  color: #6e93f7;
  transition: all .3s;
}

.wow-main .swiper-navBtn:hover {
  color: #4070f4;
}

.wow-main .swiper-navBtn:before, .wow-main .swiper-navBtn:after {
  font-size: 35px;
}

.wow-main .swiper-button-next {
  right: 0;
}

.wow-main .swiper-button-prev {
  left: 0;
}

.wow-main .swiper-pagination-bullet {
  opacity: 1;
  background-color: #6e93f7;
}

.wow-main .swiper-pagination-bullet-active {
  background-color: #4070f4;
}

.wow-main .swiper .line {
  width: 95px;
  height: 1px;
  background: #0f3a5733;
}

@media (max-width: 768px) {
  .wow-main .swiper-navBtn {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .wow-main .swiper-slide img {
    position: unset !important;
  }

  .wow-main .card-content {
    align-items: center;
  }

  .wow-main .image-content {
    height: 300px;
    width: 90vw;
    margin: auto;
  }

  .wow-main .swiper-slide img {
    height: 300px;
  }

  .wow-main .wow-content p {
    max-width: 400px;
  }

  .wow-main .swiper-scrollbar.swiper-scrollbar-horizontal {
    width: 90vw !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .wow-main .swiper-slide img {
    position: unset !important;
  }

  .wow-main .card-content {
    align-items: center;
  }

  .wow-main .image-content {
    height: 300px;
    justify-content: center;
    align-items: center;
    margin: auto;
    display: flex;
  }

  .wow-main .swiper-slide img {
    height: 300px;
  }

  .wow-main .wow-content p {
    width: 450px;
  }

  .wow-main .swiper {
    height: 540px;
  }

  .wow-main .swiper-scrollbar.swiper-scrollbar-horizontal {
    width: 400px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .wow-main .image-content, .wow-main .swiper-slide img {
    height: 400px;
  }

  .wow-main .wow-content p {
    width: 630px;
  }

  .wow-main .swiper {
    height: 640px;
  }

  .wow-main .swiper-scrollbar.swiper-scrollbar-horizontal {
    width: 540px !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .wow-main .image-content, .wow-main .swiper-slide img {
    height: 500px;
  }

  .wow-main .wow-content p {
    width: 770px;
  }

  .wow-main .swiper {
    height: 740px;
  }

  .wow-main .swiper-scrollbar.swiper-scrollbar-horizontal {
    width: 740px !important;
  }
}

@media only screen and (min-width: 1201px) {
  .wow-main .image-content, .wow-main .swiper-slide img {
    height: 618px;
  }

  .wow-main .wow-content p {
    width: 1020px;
  }

  .wow-main .swiper {
    height: 940px;
  }
}

/*# sourceMappingURL=index.8b4d116c.css.map */
